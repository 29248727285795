<template>
  <div>
    <v-card class="mb-4 mt-5">
      <v-card-title class="text-subtitle-1">
        {{ $t('account.exportSavedSearches') }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            {{ $t('account.downloadSavedSearchesPDF') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-btn
              v-if="hasNlModuleAccessAndNlSavedSearches"
              width="100%"
              @click="generateNLSavedSearchesExport"
            >
              {{ $t('account.nlSavedSearches') }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn
              v-if="hasEuModuleAccessAndEuSavedSearches"
              width="100%"
              @click="generateEUSavedSearchesExport"
            >
              {{ $t('account.euSavedSearches') }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn
              v-if="hasDlrcModuleAccessAndDlrcSavedSearches"
              width="100%"
              @click="generateNLLRSavedSearchesExport"
            >
              {{ $t('account.dlrcSavedSearches') }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn
              v-if="hasBeFedModuleAccessAndBeFedSavedSearches"
              width="100%"
              @click="generateBeFedSavedSearchesExport"
            >
              {{ $t('account.beFedSavedSearches') }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn
              v-if="hasBeFlmModuleAccessAndBeFlmSavedSearches"
              width="100%"
              @click="generateBeFlmSavedSearchesExport"
            >
              {{ $t('account.beFlmSavedSearches') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <VueHtml2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      :html-to-pdf-options="htmlToPdfOptions"
      @beforeDownload="beforeDownload($event)"
      ref="html2Pdf"
  >
      <section
        @domRendered="domRendered()"
        slot="pdf-content"
      >
          <!-- PDF Content Here -->
          <saved-searches-as-pdf
            v-if="searches && category"
            :searches="searches"
            :category="category"
            :dashboardName="dashboardName"
            :url="url"
          />
      </section>
    </VueHtml2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import SavedSearchesAsPdf from '@/components/SavedSearches/SavedSearchesAsPdf.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'AccountPreferencesExport',

  components: {
    VueHtml2pdf,
    SavedSearchesAsPdf,
  },

  data() {
    return {
      searches: null,
      category: null,
      dashboardName: null,
      url: null,
    };
  },

  computed: {
    ...mapGetters({
      username: 'userName',
      authHasModuleAccess: 'authHasModuleAccess',
      nlAllSavedSearches: 'nlAllSavedSearches',
      euAllSavedSearches: 'euAllSavedSearches',
      nlLrAllSavedSearches: 'nlLrAllSavedSearches',
      beFedAllSavedSearches: 'beFedAllSavedSearches',
      beFlmAllSavedSearches: 'beFlmAllSavedSearches',
    }),

    hasNlModuleAccessAndNlSavedSearches() {
      return (this.authHasModuleAccess('MC11') && this.nlAllSavedSearches);
    },

    hasEuModuleAccessAndEuSavedSearches() {
      return (this.authHasModuleAccess('MC07') && this.euAllSavedSearches);
    },

    hasDlrcModuleAccessAndDlrcSavedSearches() {
      return (this.authHasModuleAccess('MC13') && this.nlLrAllSavedSearches);
    },

    hasBeFedModuleAccessAndBeFedSavedSearches() {
      return (this.authHasModuleAccess('MC17') && this.beFedAllSavedSearches);
    },

    hasBeFlmModuleAccessAndBeFlmSavedSearches() {
      return (this.authHasModuleAccess('MC18') && this.beFlmAllSavedSearches);
    },

    htmlToPdfOptions() {
      return {
        margin: 1,
        image: {
          type: 'jpeg',
          quality: 0.99,
        },
        enableLinks: true,
        html2canvas: {
          scale: 2,
          useCORS: true,
        },
        jsPDF: {
          unit: 'in',
          format: 'a4',
          orientation: 'portrait',
        },
      };
    },
  },

  methods: {
    generateNLSavedSearchesExport() {
      this.searches = this.nlAllSavedSearches;
      this.category = 'nl';
      this.dashboardName = 'NL';
      this.url = '/dashboard';
      this.$refs.html2Pdf.generatePdf();
    },

    generateEUSavedSearchesExport() {
      this.searches = this.euAllSavedSearches;
      this.category = 'eu';
      this.dashboardName = 'EU';
      this.url = '/eu/dashboard';
      this.$refs.html2Pdf.generatePdf();
    },

    generateNLLRSavedSearchesExport() {
      this.searches = this.nlLrAllSavedSearches;
      this.category = 'nlLr';
      this.dashboardName = this.$t('nl-lr.shortTitle');
      this.url = '/nl-lr/dashboard';
      this.$refs.html2Pdf.generatePdf();
    },

    generateBeFedSavedSearchesExport() {
      this.searches = this.beFedAllSavedSearches;
      this.category = 'beFed';
      this.dashboardName = this.$t('be-fed.shortTitle');
      this.url = '/be-fed/dashboard';
      this.$refs.html2Pdf.generatePdf();
    },

    generateBeFlmSavedSearchesExport() {
      this.searches = this.beFlmAllSavedSearches;
      this.category = 'beFlm';
      this.dashboardName = this.$t('be-flm.shortTitle');
      this.url = '/be-flm/dashboard';
      this.$refs.html2Pdf.generatePdf();
    },

    async beforeDownload({ html2pdf, options }) {
      // For some reason we have to set the filename here.
      // If the filename is set in the htmlToPdfOptions the category part is somehow not reactive.
      options.filename = `${this.dashboardName} ${this.$t('account.savedSearchesOf')} ${this.username}`;
      await html2pdf().set(options);
    },

  },
};
</script>
