<template>
  <div>
    <v-menu
      v-model="showMenu"
      :disabled="menuItems.length <= 1"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-container
          ref="navMenuElement"
          v-if="getFieldFromPath('title')"
          @hook:mounted="initResizable"
        >
          <v-row>
            <v-col>
              <v-subheader
                class="text-subtitle-2 text-lg-subtitle-1 font-weight-medium ml-n2"
                v-bind="attrs"
                v-on="on"
                v-ripple
              >
                <span v-if="isShortNlLrTitle && $route.path === '/nl-lr/dashboard'">{{ getFieldFromPath('shortTitle') }}</span>
                <span v-else-if="isShortBeFedTitle && $route.path === '/be-fed/dashboard'">{{ getFieldFromPath('shortTitle') }}</span>
                <span v-else-if="isShortBeFlmTitle && $route.path === '/be-flm/dashboard'">{{ getFieldFromPath('shortTitle') }}</span>
                <span v-else>{{ getFieldFromPath('title') }}</span>
                <v-icon
                v-show="menuItems.length > 1"
                  class="ml-2"
                >
                  mdi-chevron-down
                </v-icon>
              </v-subheader>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in menuItems"
          :key="index"
          @click="goToSelectedRoute(item.path)"
        >
          <v-list-item-title
            :class="item.path === $route.path ? 'highlighted' : ''"
          >
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
// Vuex
import config from '@/config';
import { mapGetters } from 'vuex';
// import EventBus from '@/event-bus';

export default {
  data: () => ({
    showMenu: false,
    isShortNlLrTitle: false,
    isShortBeFedTitle: false,
    isShortBeFlmTitle: false,
    resizable: null,
  }),

  computed: {
    ...mapGetters({
      authHasModuleAccess: 'authHasModuleAccess',
      userPreferences: 'userPreferences', // TODO: not used here, remove?
    }),

    menuItems() {
      const menuItems = [];
      /*
        This is a dirty hack to make the local/regional independent working for
        certain customers. We abuse the 'MC11' module for this. In the future
        we might to do this more structured.
        Also we made sure that in the router we take care what happens when a
        user has no active modules
      */
      if (this.authHasModuleAccess('MC11')) menuItems.push({ title: 'NL dashboard', path: '/nl/dashboard' });
      if (this.authHasModuleAccess('MC07')) menuItems.push({ title: 'EU dashboard', path: '/eu/dashboard' });
      if (this.authHasModuleAccess('MC13')) menuItems.push({ title: this.$t('nl-lr.dashboard'), shortTitle: this.$t('nl-lr.dashboardShort'), path: '/nl-lr/dashboard' });
      if (this.authHasModuleAccess('MC17')) menuItems.push({ title: this.$t('be-fed.dashboard'), shortTitle: this.$t('be-fed.dashboardShort'), path: '/be-fed/dashboard' });
      if (this.authHasModuleAccess('MC18')) menuItems.push({ title: this.$t('be-flm.dashboard'), shortTitle: this.$t('be-flm.dashboardShort'), path: '/be-flm/dashboard' });
      if (this.authHasModuleAccess('MC05')) menuItems.push({ title: this.$t('agenda.dashboard'), path: '/agenda' });
      if (this.authHasModuleAccess('MC04')) menuItems.push({ title: this.$t('list.listManager'), path: '/list-manager' });
      return menuItems;
    },
  },

  methods: {
    getFieldFromPath(field) {
      const fieldValue = this.menuItems.find(
        (item) => this.$route.path.startsWith(item.path),
      );

      return fieldValue?.[field];
    },

    goToSelectedRoute(path) {
      this.$router.push({ path });
    },

    setShortNlLrTitle(value) {
      this.isShortNlLrTitle = value;
    },
    setShortBeFedTitle(value) {
      this.isShortBeFedTitle = value;
    },
    setShortBeFlmTitle(value) {
      this.isShortBeFlmTitle = value;
    },

    onResize() {
      const elementWidth = this.$refs.navMenuElement.clientWidth;
      // NL-LR short title trigger
      if (elementWidth < config.shortNlLrTitleTrigger) {
        this.setShortNlLrTitle(true);
      } else {
        this.setShortNlLrTitle(false);
      }
      // BE-FED short title trigger
      if (elementWidth < config.shortBeFedTitleTrigger) {
        this.setShortBeFedTitle(true);
      } else {
        this.setShortBeFedTitle(false);
      }
      // BE-FLM short title trigger
      if (elementWidth < config.shortBeFlmTitleTrigger) {
        this.setShortBeFlmTitle(true);
      } else {
        this.setShortBeFlmTitle(false);
      }
    },

    async initResizable() {
      await this.$nextTick(); // Wait till the DOM is finished rendering
      this.resizable = new ResizeObserver(this.onResize);
      this.resizable.observe(this.$refs.navMenuElement);
    },
  },

  beforeDestroy() {
    if (this.resizable) this.resizable.unobserve(this.$refs.navMenuElement);
  },
};
</script>

<style lang="scss" scoped>
.highlighted {
  color: #00897B;
}
</style>
