<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <div>
    <div
      v-if="emailPreferences"
    >
      <v-form
        ref="preferencesForm"
        v-model="valid"
        valid
      >
        <v-card class="mb-4 mt-5">
          <v-card-title class="text-subtitle-1">{{ $t('account.emailTimesDashboards') }}</v-card-title>
          <v-card-text>
            <v-checkbox class="mt-n1" v-model="emailPreferences.emailOnNoHits"
            :label="$t('account.noResultEmail')"
            />
            <div>
              <span v-html="$t('account.dailyEmailReceiveTime')"/>
              <v-select
                v-model="dailyEmailTime"
                class="time"
                :items="possibleEmailTimes"
                dense
              ></v-select>
              {{$t('account.oclock')}}.
            </div>
            <div
              v-if="authHasModuleAccess('MC08')"
            >
              <span v-html="$t('account.weeklyEmailReceiveDate')"/>
              <!-- v-if required if in invalid response is given-->
              <v-select v-if="emailPreferences.weeklyEmail" v-model="emailPreferences.weeklyEmail.day"
                class="weekday"
                :items="weekdays"
                item-text="label"
                item-value="value"
                dense
              ></v-select>
              {{ $t('account.at')}}
              <v-select
                v-model="weeklyEmailTime"
                class="time"
                :items="possibleEmailTimes"
                dense
              ></v-select>
              {{ $t('account.oclock')}}.
            </div>
            <div class="text-caption mt-n2 pb-2 font-italic">
              {{ $t('account.emailSettingsChangesWarning')}}
            </div>
          </v-card-text>
        </v-card>
        <v-card
        class="mb-4"
        v-if="authHasModuleAccess('MC05')"
        >
          <v-card-title class="text-subtitle-1">{{ $t('account.emailTimesAgenda') }}</v-card-title>
          <v-card-text>
            <div>
              <span v-html="$t('account.calendarPlanningReceival')" />
              <!-- dropdown with with checkboxes with days of the week -->
              <v-select v-model="emailPreferences.overviewAgendaEmail.days"
                :items="weekdays"
                class="agenda-weeks"
                item-text="label"
                item-value="value"
                multiple
                dense
            >
                <template
                  v-slot:selection="{ index }"
                >
                    <span v-if="index === 0 && emailPreferences.overviewAgendaEmail.days.length === 1" class="pl-3">
                        {{ weekdays.find(day => day.value === sortedSelectedDays[0]).label }}
                    </span>
                    <span v-if="index === 1">
                        &nbsp;&nbsp;&nbsp;{{ emailPreferences.overviewAgendaEmail.days.length }}&nbsp;{{$t('generic.days')}}
                    </span>
                </template>
              </v-select> {{$t('account.at')}}
              <!-- dropdown with with times for the email -->
              <v-select
                v-model="agendaTime"
                class="time"
                :items="possibleEmailTimes"
                dense
              ></v-select>
              {{$t('account.oclock')}}.
            </div>
            <div>
              <span v-html="$t('account.lookForAgendaPlanning')" />
              <!-- dropdown with week range of the agenda email -->
              <v-select v-model="emailPreferences.agendaTimeRange"
                class="weekday"
                :items="weekRange"
                item-text="label"
                item-value="value"
                dense
              ></v-select> {{$t('account.inTheFuture')}}
            </div>
            <div class="text-caption mt-n2 pb-2 font-italic">
              {{$t('account.settingsCalendarUpdatesWarning')}}
            </div>
            <div>
              <!-- radio buttons to choose between the types of emails -->
              {{ $t('account.calendarTypePlanning') }}
              <v-radio-group v-model="emailPreferences.overviewAgendaEmail.type"
                class="mt-1"
                row
              >
                <v-radio
                  value="basic"
                  class="radio-label"
                >
                  <template #label>
                    <div class="innerLabel">
                      <span v-html="$t('account.basicAgendaOverview')"/>
                    </div>
                  </template>
                </v-radio>
                <v-radio
                  value="extended"
                  class="radio-label"
                >
                  <template #label>
                    <div class="innerLabel">
                      <span v-html="$t('account.extensiveAgendaOverview')"/>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="mb-4">
          <v-card-title class="text-subtitle-1">{{ $t('account.vacationMode') }}</v-card-title>
          <v-card-text>
            {{ $t('account.vacationModeExplanation') }}
            <div class="text-caption pt-2 font-italic">
              {{ $t('account.missedUpdatesWarning') }}
            </div>
            <v-switch
              class="mt-2"
              v-model="vacationModeToggle"
              :label="$t('generic.on')"
            />
            <div
              v-if="vacationModeToggle"
              class="vacation-mode-end-date-box"
            >
              <div :class="this.$vuetify.theme.dark ? 'line light' : 'line dark'"/>
              {{ $t('account.automaticSwitchOff')}}
              <v-text-field
                :value="vacationModeEndDateFormatted"
                :placeholder="`(${$t('generic.date')})`"
                append-icon="mdi-calendar-range"
                dense
                readonly
                @click="datePickerModal = true"
                :error-messages="vacationModeEndDateValidationMessages"
              />

              <v-dialog
                v-if="datePickerModal"
                v-model="datePickerModal"
                persistent
                width="290px"
              >
                <v-date-picker
                  ref="datePicker"
                  v-model="datePickerDate"
                  no-title
                  :locale="userPreferences.locale"
                  :min="minVacationModeEndDate"
                  :max="maxVacationModeEndDate"
                >
                  <div class="explanation text-caption pb-2 font-italic">
                    {{ $t('account.maxEndDateFuture') }}
                  </div>
                  <div class="buttons">
                    <v-btn
                      color=""
                      @click="datePickerModal = false"
                    >
                      {{$t('generic.cancel')}}
                    </v-btn>
                    <v-btn
                      color="teal
                      lighten-1"
                      class="white--text"
                      @click="datePickerModal = false; setVacationModeEndDate();"
                    >
                    {{$t('generic.ok')}}
                    </v-btn>
                  </div>
                </v-date-picker>
              </v-dialog>
            </div>
          </v-card-text>
        </v-card>

      </v-form>
      <v-card class="mb-4">
        <account-preferences-form-email-saved-searches
          v-on:update-save-status="passthroughEvent"
        />
      </v-card>
    </div>
    <div
      v-if="!emailPreferences"
    >
      {{ $t('account.emailSettingsNotLoaded') }}
    </div>
  </div>
  <!-- eslint-enable vue/no-mutating-props -->
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import moment from 'moment';
import { mapGetters } from 'vuex';
import AccountPreferencesFormEmailSavedSearches from './AccountPreferencesFormEmailSavedSearches.vue';

export default {
  name: 'AccountPreferencesFormEmail',

  components: {
    AccountPreferencesFormEmailSavedSearches,
  },

  props: [
    'emailPreferences',
    'userPreferences',
  ],

  async mounted() {
    // Dynamically create all possible email times
    // That is 5:00 -> 22:00 currently
    const possibleEmailTimes = [];
    const hourRange = [...Array(18).keys()].map((i) => i + 5);
    hourRange.forEach((hour) => {
      possibleEmailTimes.push(`${hour}:00`, `${hour}:30`);
    });
    this.possibleEmailTimes = possibleEmailTimes.slice(0, -1); // Remove the very last one (22:30)

    // Set inital values for vacationMode variables
    const { vacationModeEndDate } = this.emailPreferences;
    if (vacationModeEndDate && (new Date(vacationModeEndDate) <= new Date())) {
      // The endDate has already passed, meaning vacation mode is not longer truely 'on'
      this.emailPreferences.vacationModeEndDate = null;
    } else if (vacationModeEndDate) {
      this.vacationModeToggle = true;
    }

    // Set inital values for templates variable
    const { templates } = this.emailPreferences;
    await this.$set(this.emailPreferences, 'templates', templates || {
      direct: null,
      periodic: null,
    });

    // We wait before allowing any form submits
    // because we seem to somehow trigger the
    // `emailPreferences`-watcher after
    // this mounted function has already returned
    await new Promise((r) => setTimeout(r, 100));
    this.componentReady = true;
  },

  data() {
    return {
      componentReady: false,

      valid: false,

      vacationModeToggle: false,
      maxVacationModeEndDate: moment().add(31, 'days').startOf('day').format('YYYY-MM-DD'),
      minVacationModeEndDate: moment().add(1, 'day').startOf('day').format('YYYY-MM-DD'),

      datePickerModal: false,
      datePickerDate: null,
      vacationModeEndDateValidationMessages: [],

      possibleEmailTimes: [], // Dynamically filled in mounted()
    };
  },

  computed: {
    ...mapGetters({
      authHasModuleAccess: 'authHasModuleAccess',
    }),
    weekdays() {
      return [
        { label: this.$t('daysOfWeek.monday'), value: 1 },
        { label: this.$t('daysOfWeek.tuesday'), value: 2 },
        { label: this.$t('daysOfWeek.wednesday'), value: 3 },
        { label: this.$t('daysOfWeek.thursday'), value: 4 },
        { label: this.$t('daysOfWeek.friday'), value: 5 },
        { label: this.$t('daysOfWeek.saturday'), value: 6 },
        { label: this.$t('daysOfWeek.sunday'), value: 0 },
      ];
    },
    weekRange() {
      return [
        { label: `1 ${this.$t('generic.week')}`, value: 1 },
        { label: `2 ${this.$t('generic.weeks')}`, value: 2 },
        { label: `3 ${this.$t('generic.weeks')}`, value: 3 },
        { label: `4 ${this.$t('generic.weeks')}`, value: 4 },
        { label: `5 ${this.$t('generic.weeks')}`, value: 5 },
        { label: `6 ${this.$t('generic.weeks')}`, value: 6 },
        { label: `7 ${this.$t('generic.weeks')}`, value: 7 },
        { label: `8 ${this.$t('generic.weeks')}`, value: 8 },
      ];
    },
    vacationModeEndDateFormatted() {
      if (!this.emailPreferences.vacationModeEndDate) return null;
      return new Date(this.emailPreferences.vacationModeEndDate)
        .toLocaleDateString(this.userPreferences.locale);
    },
    dailyEmailTime: {
      get() { return this.getTime('dailyEmail'); },
      set(time) { this.setTime(time, 'dailyEmail'); },
    },
    weeklyEmailTime: {
      get() { return this.getTime('weeklyEmail'); },
      set(time) { this.setTime(time, 'weeklyEmail'); },
    },
    agendaTime: {
      get() { return this.getAgendaTime(); },
      set(time) { this.setAgendaTime(time); },
    },
    directOptions() {
      return Object.values(this.emailTemplateOptions.direct);
    },
    periodicOptions() {
      return Object.values(this.emailTemplateOptions.periodic);
    },
    directEmailTemplate: {
      get() { return this.getEmailTemplate('direct'); },
      set(version) { this.setEmailTemplate('direct', version); },
    },
    periodicEmailTemplate: {
      get() { return this.getEmailTemplate('periodic'); },
      set(version) { this.setEmailTemplate('periodic', version); },
    },
    sortedSelectedDays() {
      return [...this.emailPreferences.overviewAgendaEmail.days].sort((a, b) => {
        // If a is Sunday (value 0) and b is not, a should come after b
        if (a === 0 && b !== 0) return 1;
        // If b is Sunday (value 0) and a is not, b should come after a
        if (b === 0 && a !== 0) return -1;
        // Otherwise, just compare the values as usual
        return a - b;
      });
    },
  },

  watch: {
    vacationModeToggle(value) {
      if (value) {
        // hide spinner/saved status to extra clarify nothing is yet saved
        // When only the toggle is turned on
        this.$emit('update-save-status', null, true);
      } else {
        // Turn off vacation mode
        this.emailPreferences.vacationModeEndDate = null;
      }
    },
    'emailPreferences.vacationModeEndDate': {
      handler(value) {
        this.vacationModeEndDateValidationMessages = value ? [] : [this.$t('account.mandatoryField')];
      },
      immediate: true, // Run on being mounted
    },
    emailPreferences: {
      handler: 'submitForm',
      deep: true,
    },
  },

  methods: {
    setVacationModeEndDate() {
      this.emailPreferences.vacationModeEndDate = moment(this.datePickerDate).endOf('day').toISOString();
    },
    getTime(type) {
      if (!this.emailPreferences[type]) return null;
      const emailTime = this.emailPreferences[type];
      return `${emailTime.hour}:${emailTime.minutes.toString().padStart(2, '0')}`;
    },
    setTime(time, type) {
      this.emailPreferences[type].hour = parseInt(time.split(':')[0], 10);
      this.emailPreferences[type].minutes = parseInt(time.split(':')[1], 10);
    },
    getAgendaTime() {
      if (!this.emailPreferences.overviewAgendaEmail) return null;
      const emailTime = this.emailPreferences.overviewAgendaEmail;
      return `${emailTime.hour}:${emailTime.minutes.toString().padStart(2, '0')}`;
    },
    setAgendaTime(time) {
      this.emailPreferences.overviewAgendaEmail.hour = parseInt(time.split(':')[0], 10);
      this.emailPreferences.overviewAgendaEmail.minutes = parseInt(time.split(':')[1], 10);
    },

    async submitForm() {
      if (!this.componentReady) return;

      // show spinner
      this.$emit('update-save-status', 'loading');

      if (this.$refs.preferencesForm.validate()) {
        this.busy = true;
        this.disabled = true;

        const success = await this.$store.dispatch('setEmailPreferences', this.emailPreferences);

        if (success && success.emailConfig) {
          this.$store.dispatch('setMessage', {
            message: this.$t('account.settingsSavedSuccessfully'),
            type: 'success',
          });
          // show saved
          this.$emit('update-save-status', 'saved');
        } else {
          this.$store.dispatch('setMessage', {
            message: this.$t('account.settingsSavedError'),
            type: 'error',
          });

          // hide spinner
          this.$emit('update-save-status', null);
        }
      } else {
        // hide spinner
        this.$emit('update-save-status', null);
      }
    },
    passthroughEvent(value) {
      this.$emit('update-save-status', value);
    },
  },
};
/* eslint-enable vue/no-mutating-props */
</script>

<style scoped lang="scss">

.v-card__text {
  padding: 0px 0px 0px 16px;
}

.text-subtitle-1 {
  padding-bottom: 8px;
  padding-top: 8px;
}

.v-select {
  display: inline-block;

  &.time {
    width: 6em;
  }

  &.weekday {
    width: 8em;
  }

  &.agenda-weeks {
    width: 10em;
  }

  ::v-deep .v-select__selections {
    .v-select__selection {
      text-align: center;
      width: 100%;
    }
  }
}
::v-deep .v-radio {
  display: flex;
  align-items: flex-start;

  .innerLabel {
    margin-top: 0.1em;
  }

  label {
    font-size: 0.9em;
  }
}

.v-input--switch, ::v-deep .v-messages {
  min-height: 0;
}

.vacation-mode-end-date-box {
  .line {
    display: inline-block;
    width: 1.5em;
    height: 1em;
    margin: 3px 5px 3px 18px;
    border-bottom: 1px solid black;
    border-left: 1px solid black;

    &.dark {
      border-color: rgba(0, 0, 0, 0.6);
    }

    &.light {
      border-color: rgba(255, 255, 255, 0.7);
    }
  }

  .v-text-field {
    display: inline-block;
    width: 10em;

    ::v-deep input {
      text-align: center;
    }
  }
}

::v-deep .v-picker__actions {
  flex-direction: column;

  .buttons {
    width: 100%;
    text-align: right;

    .v-btn {
      margin-left: 8px;
    }
  }
}
</style>
