<template>
  <div>
    <div>
      <img
        height="64"
        src="@/assets/logo-transparant-groot.png"
      />
      <div
        class="spacer"
      />
    </div>

    <ul>
      <li><strong>{{ dashboardName }} {{$t('savedSearches.savedSearchesFor')}} {{ username }}</strong></li>
      <li
        v-for="search in searches.data"
        :key="search.id"
      >
        <a
          :href="createSavedSearchShareUrl(search)"
        >
          {{ search.title }}
        </a>
      </li>
    </ul>

    <div>
      <div
        class="spacer"
      />
        <p>
          {{ $t('generic.poweredBy') }} <a href="https://www.polpo.nl">Polpo</a> - {{ $t('generic.polpoSmartPolicyMonitoring' )}}
        </p>
      <div class="image-spacer">
        <img
          height="32"
          src="@/assets/logo-transparant-groot.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

  props: {
    searches: {
      type: Object, // TODO something is wrong here with the imput someimte being an Object and sometimes an Array ???
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
    dashboardName: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },


  computed: {
    ...mapGetters({
      username: 'userName',
    }),
  },

  methods: {
    renderQueryParams(element) {
      const queryParams = {
        query: element.queryDetails.query,
        groupPaths: element.queryDetails.groupPaths.join(','),
      };
      if (this.category === 'nl') {
        queryParams.context = element.queryDetails.context.join(',');
      } else if (this.category === 'eu') {
        queryParams.committees = element.queryDetails.euEpCommittees.join(',') || [];
      }
      return queryParams;
    },

    createSavedSearchShareUrl(element) {
      const queryParams = new URLSearchParams(this.renderQueryParams(element)).toString();
      return `${this.url}?${queryParams}`;
    },
  },

};
</script>

<style lang="scss" scoped>
  p {
    margin: 7px 0;
  }
  p, p a, li, li a {
    text-decoration: none;
    color: #004c6f;

    font-family: Arial, Helvetica, sans-serif;
  }
  .spacer {
    border-top: 2px solid #004c6f;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .image-spacer {
    margin-top: 12px;
  }
  ul {
    li {
      margin: 7.1px 0;
    }
  }
</style>
